.btn-primary {
  background-color: #feca45 !important;
  border: none;
  color: #000 !important;
}
.content-space-t-lg-2 {
  padding-top: 3rem !important;
}
.dropdown-toggle::after {
  border-top: none !important;
}
/* a{
  color:#2d3773 !important
} */
.text-blue {
  color: #2d3773 !important;
}
