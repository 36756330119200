.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.td-sr {
  /* width: 1%; */
  white-space: nowrap;
}

.h-full {
  height: 100%;
}

.eyeOne::-ms-reveal,
.eyeOne::-ms-clear {
  display: none;
}

/* .modal-header {
  padding: 16px !important;
} */

.modal-content {
  padding: 8px !important;
}
.filterScroll{
max-height: 90%;
overflow-y: auto;
overflow-x: hidden;

}
.scrollBody{
  scrollbar-width: none;
}
.scrollBody::-webkit-scrollbar{
  display: none;
}


.ck-editor__editable {
  height: 500px;
  overflow-y: scroll;
}

.ck-body-wrapper{
  display: none;
}