.form-select {
    padding: 0.75rem 1.4375rem 0.75rem !important;
    background-position: right 0.3125rem center !important;
  }
  .btn-primary {
    background-color: #feca45 !important;
    border: none;
    color: #000 !important;
  }
  .content-space-t-lg-2 {
    padding-top: 3rem !important;
  }