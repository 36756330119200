.btn-primary {
    background-color: #feca45 !important;
    border: none;
    color:#000 !important;
  }
  .content-space-t-lg-2{
    padding-top: 3rem!important
  }
  .numberCircle {
      font-size:20px;
      width: 1.5em;
      height: 1.5em;
      box-sizing: initial;

      background: #fff;
      border: 0.1em solid #666;
      color: #666;
      text-align: center;
      border-radius: 50%;    

      line-height: 1.5em;
      box-sizing: content-box;   
  }
  .card-body .padding-10{
      padding: 10px;
  }
  .fw-bold{padding-top:10px !important}
  .pull-right {
      float: right;
  }
  .card table>tbody>tr>td{padding: 10px 10px ;}
  .card table>tbody>tr:first-child>td{padding-top: 10px;}
  .card table>tbody>tr:last-child>td{padding-bottom: 10px;}
  ul.timeline {
      list-style-type: none;
      position: relative;
  }
  ul.timeline:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
  }
  ul.timeline > li {
      margin: 20px 0;
      padding-left: 20px;
  }
  ul.timeline > li:before {
      content: ' ';
      background: white;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #22c0e8;
      left: 20px;
      width: 20px;
      height: 20px;
      z-index: 400;
  }
  ul.timeline:before{ margin-top: 5px;}
  ul.timeline > li:before {margin-top: 4px;}
  .list-group-item {
      
      padding: 5px !important;
      margin-bottom: unset !important;
      background-color: none !important;
  }