.btn-primary {
    background-color: #feca45 !important;
    border: none;
    color:#000 !important;
  }
  .content-space-t-lg-2{
    padding-top: 3rem!important
  }
  .numberCircle {
      font-size:20px;
      width: 1.5em;
      height: 1.5em;
      box-sizing: initial;

      background: #fff;
      border: 0.1em solid #666;
      color: #666;
      text-align: center;
      border-radius: 50%;    

      line-height: 1.5em;
      box-sizing: content-box;   
  }
  .card-body .padding-10{
      padding: 10px;
  }
  .fw-bold{padding-top:10px !important}
  .pull-right {
      float: right;
  }
  .form-select{ padding:5px !important;    font-size: .8125rem; }