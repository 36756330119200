demo {
    background-color: red !important ;
  }
  /* .rbc-btn-group {
    display: none; 
  } */
  /* .modal-body {
    padding: 0 !important;
  } */
  .rbc-toolbar-label {
    background-color: #270640 !important;
    padding: 10px !important;
    color: #fff !important;
    position: absolute;
    top: -31px;
    left: 0;
  }
  .modal-header {
    padding: 8px !important;
    border: 0 !important;
  }
  .rbc-toolbar {
    margin-bottom: 0;
    position: relative;
    padding: 20px;
  }
  .rbc-toolbar span:last-of-type{
    display: none !important;
  }
  .rbc-event {
    position: absolute;
    top: -18px;
    left: 0;
    /* z-index: 1111; */
    height: 113px;
    background:#4acfee;
    height: 80px;
    /* padding: 100%; */
    display: flex;
    /* margin-top: 15px; */
    height: "100%";
    justify-content: center;
    color: #270640;
  }
  .rbc-header {
    padding: 5px;
  }
  .modal-header{
    border: 0;
  }

  .rbc-row-segment{
    position: relative;
  }

  .rbc-date-cell{
    position: relative;
  }

  .rbc-button-link{
    z-index: 1111;
    position: absolute;
    top: 0;
    /* left: 41px; */
    right: 0;
  }